@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro');

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    width: 100%;
    font-family: 'Anonymous Pro', sans-serif;
    color: $dark;
}

#root {
    height: 100%;
}
